import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  iDefaultLimit: number = 1000;

  constructor(
    private cache: CacheService,
  ) {

  }

  calculateBack(view: any, config: any | null = null) {
    config = config || {};
    config.offset = config.offset || 0;
    config.offset--;

    return this.calculateConfig(view, config);
  }

  async calculateConfig(view: any, config: any | null = null) {
    config = config || {};
    config.limit = config.limit || (await this.getLimit());
    config.offset = config.offset || 0;

    // calc view vars
    config.canBack = !!config.offset;
    config.canNext = false;

    if (!config.itemsKey) {
      return false;
    }

    // store to backup if not exists
    if (!config.hasOwnProperty('backup') && !!config.itemsKey && !!config.itemsParentKey && !!view[config.itemsParentKey] && !!view[config.itemsParentKey][config.itemsKey]) {
      config.backup = JSON.parse(JSON.stringify(view[config.itemsParentKey][config.itemsKey]));
    } else
      if (!config.hasOwnProperty('backup') && !!config.itemsKey && !!view[config.itemsKey]) {
        config.backup = JSON.parse(JSON.stringify(view[config.itemsKey]));
      }

    // restore from backup if exists
    if (!!config.backup) {
      if (!!config.itemsParentKey) {
        view[config.itemsParentKey][config.itemsKey] = config.backup;
      } else {
        view[config.itemsKey] = config.backup;
      }
    }

    let buttons: any = [];

    const blOverLimit: boolean = (!!config.itemsParentKey ? (view[config.itemsParentKey][config.itemsKey].length > config.limit) : (view[config.itemsKey].length > config.limit)),
      iStart: number = (config.offset * config.limit),
      iEnd: number = (iStart + config.limit);

    if ((!!config.itemsKey && !!view[config.itemsKey]) || (!!config.itemsKey && !!view[config.itemsParentKey] && !!view[config.itemsParentKey][config.itemsKey]) && !!config.limit && !!blOverLimit) {

      if (!!config.itemsParentKey) {
        view[config.itemsParentKey][config.itemsKey] = view[config.itemsParentKey][config.itemsKey].slice(iStart, iEnd);
      } else {
        view[config.itemsKey] = view[config.itemsKey].slice(iStart, iEnd);
      }

      // calculate buttons
      const iSteps: number = parseInt(`${config.backup.length / config.limit}`);
      let iCurrent: number = 1;

      while (iSteps > iCurrent) {
        buttons.push({
          index: iCurrent,
          label: (iCurrent + 1),
        });
        iCurrent++;
      }

      config.canNext = !!blOverLimit;
    }

    return {
      buttons: buttons,
      config: config,
      view: view,
    }
  }

  calculateNext(view: any, config: any | null = null) {
    config = config || {};
    config.offset = config.offset || 0;
    config.offset++;

    return this.calculateConfig(view, config);
  }

  getDefaultLimit() {
    return this.iDefaultLimit;
  }

  async getLimit() {
    const fromCache: any = await this.cache.get('pagination_limit', -1);
    console.log('pagination: limit fromCache', fromCache);

    return (!!fromCache && !!fromCache.data ? parseInt(`${fromCache.data}`) : this.getDefaultLimit());
  }

  setLimit(iLimit: number) {
    return this.cache.set('pagination_limit', iLimit);
  }

}