<ion-toolbar *ngIf="!!config">

    <ion-buttons slot="start">

        <!-- Prev -->
        <ion-button [disabled]="!config.canBack" (click)="paginateBack()" fill="clear" color="dark">
            <ion-icon name="arrow-back"></ion-icon>
        </ion-button>

        <!-- Between items -->
        <ion-button [disabled]="!!config.disabled" *ngFor="let button of buttons" (click)="onButtonClick(button)"
            [fill]="config.offset === button.index ? 'solid' : 'clear'"
            [color]="config.offset === button.index ? 'primary' : 'dark'">
            <ion-label [innerHTML]="button.label"></ion-label>
        </ion-button>

        <!-- Next -->
        <ion-button [disabled]="!config.canNext" (click)="paginateNext()" fill="clear" color="dark">
            <ion-icon name="arrow-forward"></ion-icon>
        </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

        <!-- Limit picker -->
        <ion-select [label]="'items_count'|translate" [placeholder]="'select'|translate" interface="popover"
            justify="space-between" alignment="end" labelPlacement="start" [okText]="'okay'|translate"
            [cancelText]="'cancel'|translate" [(ngModel)]="config.limit" (ionChange)="onLimitChanged($event)">
            <ion-select-option [value]="0">Alle</ion-select-option>
            <ion-select-option [value]="100">100</ion-select-option>
            <ion-select-option [value]="250">250</ion-select-option>
            <ion-select-option [value]="500">500</ion-select-option>
            <ion-select-option [value]="1000">1000</ion-select-option>
        </ion-select>

    </ion-buttons>

</ion-toolbar>