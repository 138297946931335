import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { IntroService } from 'src/app/services/utils/intro.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { StockphotosService } from "src/app/services/stockphotos/stockphotos.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-web-uploader',
  templateUrl: './web-uploader.page.html',
  styleUrls: ['./web-uploader.page.scss'],
})
export class WebUploaderPage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';
  
  media: any;

  search: searchOptions = {
    itemsKey: 'mediaList',
    keys: ['name', 'post_content', 'host', 'url', 'uid'],
    query: "",
  };

  state: state = {};
  
  view: any = {
    allowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'svg', 'webp'],
    allowedVideoExtensions: ['mp4', 'mov', 'm4v'],
    assetsView: {
      colSize: (!!this.tools.isDesktop() ? 4 : 12),
    },
    canSubmit: false,
    colSize: (window.innerWidth > 1440 ? 2 : (this.tools.isDesktop() ? 3 : 6)),
    filters: {},
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    imagesColSize: (this.tools.isDesktop() ? 3 : 6),
    introCard: {
      uid: 'web_uploader_top_card',
      //subtitle: 'web_uploader_top_card_subtitle',
      text: 'web_uploader_top_card_text',
      title: 'web_uploader_top_card_title',
    },
    libraryView: {
      mode: 'pick', // full, pick
      multiple: true,
      showViewModeSelect: true,
    },
    mediaFolders: [],
    mediaList: [],
    mediaType: 'asset',
    mimeType: 'image',
    mimeTypes: [
      'document',
      'video',
      'image',
    ],
    options: {},
    segment: 'upload',
    selectionOptions: [
      {
        icon: 'download-outline',
        label: 'download',
        uid: 'download',
      },
      {
        icon: 'cloud-download-outline',
        label: 'import',
        uid: 'import',
      },
      {
        icon: 'checkmark-outline',
        label: 'use',
        uid: 'use',
      },
    ],
    services: {
      ai: true,
      database: true,
      media_library: true,
      upload: true,
    },
    showProjectsSelect: false, // @debug changes project in the background, disabled
    title: 'add_files',
    type: 'url',
  }

  constructor(
    private events: EventsService,
    private introService: IntroService,
    private modalService: ModalService,
    private navParams: NavParams,
    private stock: StockphotosService,
    private tools: ToolsService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.media = this.navParams.get('mediaService');

    let folderId: string = this.navParams.get('folder_uid');
    this.view.folder_uid = (!!folderId ? parseInt(folderId) : null);
    
    this.view.source = this.navParams.get('source');
    this.view.services = this.calcServices(this.navParams.get('services')) || this.view.services;
    this.view.mediaType = this.navParams.get('mediaType') || 'asset';
    this.view.multiple = this.navParams.get('multiple');
    
    this.view.libraryView.multiple = !!this.view.multiple;
    this.view.libraryView.source = 'media_library'; // (this.view.mediaType === 'asset' ? 'assets' : 'creatives');
    console.log('this.view.libraryView', this.view.libraryView);
    
    let query: string = this.navParams.get('query');
    let tab: string = this.navParams.get('tab');
    
    if(!!query) {
      this.search.query = query;
    }

    this.calcAllowedOptions();
    
    if(!!tab) {
      this.view.segment = tab;
      this.runSearch();
    }
    
  }

  calcAllowedOptions() {
    this.zone.run(() => {
      switch (this.view.source) {
        case 'youtube':
          this.view.allowFileUpload = false;
          this.view.allowFromMedia = false;
          this.view.allowUrl = true;
          this.view.allowYoutubeEmbed = true;
          break;
        default:
          this.view.allowFileUpload = true;
          this.view.allowFromMedia = true;
          this.view.allowUrl = true;
          this.view.allowYoutubeEmbed = false;
          break;
      }
    });
  }

  calcColSize() {
    this.view.colSize = (window.innerWidth > 1440 ? 2 : (this.tools.isDesktop() ? 3 : 6));
    this.view.imagesColSize = (!!this.tools.isDesktop() ? 4 : 12);

    this.view.assetsView.colSize = this.view.imagesColSize;
  }

  calcServices(servicesNames: string[]) {
    let services: any = {};
    servicesNames.forEach((serviceName: string) => {
      services[serviceName] = true;
    });
    return services;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canSubmit = !!this.view.thumbnailImg || (this.view.mediaList && this.view.mediaList.length);

    this.view.hideGetGeniusWallet = (this.view.segment !== 'ai');
  }

  chooseMediaFolder(folder: folder) {
    this.view.loading = true;

    this.media.getByFolder(folder.uid)
    .then((response: any) => {
      this.zone.run(() => {
        this.view.loading = false;
        this.view.subMode = true;

        delete this.view.mediaList_backup;
        this.detectChanges();

        if(!!response && !!response.length) {
          this.view.mediaList = response.map((_mediaItem: mediaItem) => {
            delete _mediaItem.size;
            delete _mediaItem.thumbnails;
            return _mediaItem;
          });
          
          if(!!this.view.mediaList[0] && !!this.view.mediaList[0].thumbnail) {
            this.chooseMediaFromList(this.view.mediaList[0], 0);
          }
        } else {
          this.view.mediaList = [];
        }

        this.runSearch();
      })
    })
    .catch((error: any) => {
      this.view.loading = false;
      console.warn('> error', error);
    });
  }

  chooseMediaFromList(media: any, index: number, list: any = null) {
    list = list || this.view.mediaList;

    if(!media) {
      return false;
    }
    
    if(!!this.view.multiple) {
      if(typeof list[index] === 'string') {
        this.view.selectedStrings[media] = !this.view.selectedStrings[media];
      } else {
        list[index].checked = !list[index].checked;
      }
    } else
    if(!!list) {

      list.forEach((_media: any) => {
        if(typeof _media === 'string') {
          console.log('_media is string', _media);
        } else {
          let bl: boolean = (((!!media.ID && (media.ID === _media.ID)) || (!!media.original_uid && (media.original_uid === _media.original_uid))) || (media.thumbnail === _media.thumbnail)) && !_media.checked;
          _media.checked = bl;
  
          if(!!bl) {
            this.view.item = _media;
          }
        }
      });

    }
    
    console.log('list', list);

    if(!!list) {
      this.view.items = list.filter((_media: any) => {
        if(typeof _media === 'string') {
          return !!this.view.selectedStrings[_media];
        } else
        if(!!_media) {
          return !!_media.checked;
        }
      });
    }

    if(typeof media === 'string') {
      this.view.thumbnailImg = media;
    } else
    if(!!media.photo || !!media.thumbnail) {
      this.view.thumbnailImg = (media.post_mime_type === 'video' ? media.videoSrc : (media.photo || media.thumbnail));
    }

    if(!!this.view.thumbnailImg) {
      this.view.url = `${this.view.thumbnailImg}`;
    }

    this.view.hasThumbnailImg = !!this.view.thumbnailImg;
    this.view.canSubmit = !!this.view.thumbnailImg;
    
    let selectedStringsKeys: string[] = Object.keys(this.view.selectedStrings || []);

    if(!!selectedStringsKeys && !!selectedStringsKeys.length) {
      let _mediaList: any[] = [];

      selectedStringsKeys.forEach((url: string) => {
        if(!!this.view.selectedStrings[url]) {
          _mediaList.push({
            checked: true,
            photo: `${url}`,
            thumbnail: `${url}`,
          });
        }
      });

      console.log('this.view.mediaList (a)', this.view.mediaList);

      this.view.mediaList = _mediaList;
    }

  }

  detectChanges() {
    //console.log('web-uploader: should detect changes');
  }

  async dismiss(data: any = null, role: string|null = 'dismiss') {
    this.importSelectedExternalMedia();

    return this.modalService.dismiss(data, role);
  }

  doRefresh(event: any = null) {
    this.events.publish('media:library:refresh');
  }

  fileThumbnailLoadingFailed(file: any) {
    file.thumbnail = this.fallbackImg;
  }

  generate() {
    this.events.publish('media:creator:run', {
      view: this.view,
    });
  }

  importSelectedExternalMedia() {
    if(!!this.view.mediaList && !!this.view.mediaList.length) {
      let blRequiresRefresh: boolean = false;

      this.view.mediaList.forEach((mediaItem: mediaItem) => {
        if(!!mediaItem.checked && !mediaItem.ID && (!!mediaItem.photo || !!mediaItem.thumbnail)) {
          try {
            this.media.importFromUrl(mediaItem.photo || mediaItem.thumbnail);
            blRequiresRefresh = true;
          } catch(e) {
            console.warn('importing media failed', e);
          }
        }
      });

      if(!!blRequiresRefresh) {
        this.doRefresh();
      }

    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaLibrarySelectionChanged = this.events.subscribe('media:library:selection:changed', (data: any) => {
      console.log('media:library:selection:changed', data);

      if(!!data.items) {
        this.view.items = data.items;
        this.view.canSubmit = !!(data.items && data.items.length);
      }
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      delete this.view.queue_backup;

      this.doRefresh();
    });

    this.view.events.selectionChanged = this.events.subscribe('selection:changed', (data: any) => {

      this.view.mediaList = ((!!data && !!data.items ? data.items : this.search.results) || []).filter((result: any) => {
        return !!result.checked;
      });

      console.log('updated this.view.mediaList', this.view.mediaList);

      this.calcViewVars();
    });

    this.view.events.webUploaderFilesUpdated = this.events.subscribe('web:uploader:files:updated', (files: any) => {
      console.log('web:uploader:files:updated', files);
      this.view.files = files;
    });

  }

  initWebUploads() {
    let input: any = document.querySelector('input[type="file"][class="web-uploader-file"]');
    let _this = this;
    
    if(!input) {
      console.warn('missing web uploader input');
      return false;
    }

    input.addEventListener('change', function () {
      _this.view.filesList = this.files;
      console.log('_this.view.filesList', _this.view.filesList);

      let files: any[] = [];
      const img: any = document.querySelector('img[class="thumbnail-preview"]');

      for (var i = 0; i < this.files.length; i++) {
        this.files[i].src = URL.createObjectURL(this.files[i]); // set src to blob url
        files.push(this.files[i]);
      }

      _this.view.files = files;
      console.log('this.files', this.files);

      if (this.files && this.files[0]) {

        if(!img) {
          return false;
        }

        img.onload = () => {
          URL.revokeObjectURL(img.src);  // no longer needed, free memory
        };

        img.src = URL.createObjectURL(this.files[0]); // set src to blob url

        let params: any = {};

        if(!!_this.view.folder_uid) {
          params.folder_uid = _this.view.folder_uid;
        }

        _this.media.uploadUsingWeb(this.files, null, params)
          .then((response: any) => {
            console.log('uploadUsingWeb response', response);

            if (response.thumbnail) {
              _this.view.items = [response];
              _this.view.url = response.thumbnail;
              _this.view.canSubmit = true;

              _this.doRefresh();
            } else {
              _this.events.publish('error', response);
            }
          })
          .catch((error: any) => {
            _this.events.publish('error', error);
          });
      }
    });

  }

  ionImgDidLoad(event: any = null) {
    this.view.canSubmit = true;
  }

  ionViewWillEnter() {
    this.initEvents();

    this.initWebUploads();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
  }

  ngOnInit() {
    this.calcColSize();
    this.calcViewVars();    
  }

  onFileMultiItemClick(file: any) {
    if(!!this.view.files) {
      this.view.items = [...this.view.files].filter((_file: any) => {
        return _file.checked;
      }).map((_file: any) => {

        if(!!_file && !!_file.response && !!_file.response.link) {
          return _file.response.link;
        }

        return {
          post_mime_type: (_file.url.indexOf('.mp4') === -1 ? 'image' : 'video'),
          thumbnail: _file.url,
        };
      });
      
      console.log('this.view.items is now', this.view.items);
    }
  }
  
  @HostListener('window:resize')
  onResize() {
    this.calcColSize();
    this.calcViewVars();
  }

  onSelectionActionChanged(event: any = null) {
    console.log('uploader: onSelectionActionChanged', this.view);
  }

  onSelectionChanged(event: any = null) {
    console.log('web-uploader: onSelectionChanged', event);

    this.view.mediaList = (!!event && !!event.items ? event.items : []);
    this.view.hasSelectedItems = !!(!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.calcViewVars();
  }

  onViewChanged(event: any = null) {
    console.log('web-uploader: onViewChanged', event);

    if(!!event) {
      this.view.assetsView = Object.assign(this.view.assetsView, event);
      
      this.events.publish('assets:creator:update', this.view.assetsView);
    }
  }

  runSearch(event: any = null) {
    switch(this.view.segment) {
      case 'database':
        return this.runStockSearch();
      default:
        console.log('web-uploader: runSearch', event);
        console.log('this.view', this.view);
        break;
    }
  }
  
  runStockSearch(blForceRefresh: boolean = false) {

    if(!this.search.query || (this.search.query.length < 2)) {
      return false;
    }
    
    this.stock.search(this.search.query, this.view.options, blForceRefresh)
    .then((response: any) => {
      this.zone.run(() => {
        if(!!response && response.results) {
          this.search.results = this.tools.shuffle(response.results).slice(0, 100);
          this.detectChanges();
        }
      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async segmentChanged() {

    // intro card
    this.view.introCard.uid = 'web_uploader_top_card';
    this.view.introCard.subtitle = 'web_uploader_top_card_subtitle';
    this.view.introCard.text = 'web_uploader_top_card_text';
    this.view.introCard.title = 'web_uploader_top_card_title';

    this.view.introCard.hidden = await this.introService.isIntroCardHidden(this.view.introCard.uid);

    this.view.hideGetGeniusWallet = (this.view.segment !== 'ai');

    switch(this.view.segment) {
      case 'media_library':
        this.up();
        break;
    }

  }

  submit() {
    this.validateBeforeSubmit()
      .then(() => {

        console.log('this.view.mediaList', this.view.mediaList);
        console.log('this.view.items: before', JSON.parse(JSON.stringify(this.view.items)));

        if(!!this.view.multiple && !!this.view.mediaList && !!this.view.mediaList.length && !this.view.items) {
          this.view.items = this.view.mediaList.filter((_mediaItem: mediaItem) => {
            return !!_mediaItem.checked;
          });
        }

        console.log('this.view.items: after', JSON.parse(JSON.stringify(this.view.items)));        
        this.dismiss(this.view, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(media: mediaItem = null) {
    setTimeout(() => {

      if(!!media) {
        media.thumbnail = this.fallbackImg;
      }

      this.zone.run(() => {
        this.view.hasThumbnailImg = false;
        this.view.thumbnailImg = false;
      });
    });
  }

  up() {
    console.log('web-uploader: should load root media library');
  }

  urlChanged() {

    if (this.view.source === 'youtube') {
      return this.youtubeUrlChanged();
    }

    let extensionExplode = (this.view.url || '').split('.'),
      extension = extensionExplode[extensionExplode.length - 1];

    this.zone.run(() => {
      this.view.hasThumbnailImg = !!(extension && (this.view.allowedExtensions.indexOf(extension) !== -1));
      if (this.view.hasThumbnailImg) {
        this.view.thumbnailImg = this.view.url;
      }
    });
  }

  validateBeforeSubmit() {
    return new Promise((resolve, reject) => {
      switch (this.view.source) {
        case 'youtube':
          this.validateYouTubeBeforeSubmit().then(resolve).catch(reject);
        default:
          resolve(true);
          break;
      }
    });
  }

  validateYouTubeBeforeSubmit() {
    return new Promise((resolve, reject) => {
      if (this.view.type === 'url') {
        let shortLinkExplode = this.view.url.split('?v='),
          shortLink = shortLinkExplode[shortLinkExplode.length - 1],
          embedUrl = `https://www.youtube.com/embed/${shortLink}`;

        this.view.code = `<iframe width="560" height="315" src="${embedUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        resolve(true);
      } else
        if (this.view.type === 'youtube_embed') {
          this.view.code = this.view.youtube_embed_code;
          resolve(true);
        }
    });
  }

  youtubeEmbedCodeChanged() {
    this.zone.run(() => {
      this.view.canSubmit = !!(
        (this.view.youtube_embed_code || '').indexOf('<iframe') !== -1 &&
        (this.view.youtube_embed_code || '').indexOf('https://www.youtube.com/embed/') !== -1 &&
        (this.view.youtube_embed_code || '').indexOf('</iframe>') !== -1
      );
    });
  }

  youtubeUrlChanged() {
    this.zone.run(() => {
      this.view.canSubmit = !!((this.view.url || '').indexOf('https://www.youtube.com/watch?v=') !== -1);
    });
  }

}