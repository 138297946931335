import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pipeline-header-search-toolbar',
  templateUrl: './header-search-toolbar.component.html',
  styleUrls: ['./header-search-toolbar.component.scss'],
})
export class HeaderSearchToolbarComponent implements OnInit {
  @Input() items: any[];
  @Input() placeholder: string;
  @Input() search: searchOptions;
  @Input() view: any;

  @Output() onChange = new EventEmitter();

  constructor(
  ) {

  }
  
  getItemsKeys() {
    return (this.search.itemsKeys || [this.search.itemsKey || 'items']);
  }

  ngOnInit() {
    
  }

  public runSearch(event: any = null, options: any = {}) {
    let items: any[] = [], itemsKeys: string[] = this.getItemsKeys();
    
    itemsKeys.forEach((key: string) => {
      if(this.view.hasOwnProperty(key)) {

        let backupKey: string = `${key}_backup`,
            searchKeys: string[] = (this.search.keys || ['name', 'title', 'uid', 'url']);

        if(!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
          this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key].map((item: any) => {
            delete item.view;
            return item;
          })));
        } else
        if(this.view[backupKey] && this.view[backupKey].length) {
          this.view[key] = JSON.parse(JSON.stringify(this.view[backupKey]));
        }

        let query: string = `${this.search.query}`.toLowerCase();

        if(this.view[key] && this.view[key].length) {
          this.view[key] = this.view[key].filter((item: any) => {
            let blShow: boolean = (!query || !query.length);

            if(!!query) {
              searchKeys.forEach((searchKey: string) => {
                if(item.hasOwnProperty(searchKey)) {
                  blShow = (blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(query) !== -1);
                }
              });
            }

            if(!!blShow) {
              items.push(item);
            }

            return blShow;
          });
        }
      }
    });

    this.onChange.emit({
      items: items,
      search: this.search,
      view: this.view,
    });
  }
  
  public updateItems(items: any[], key: string = 'items') {
    this.view[key] = items;
    this.view[`${key}_backup`] = items;

    return this;
  }
  
}
