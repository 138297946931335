import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/services/core/events.service';

import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  interval: any;

  status: any;

  constructor(
    private events: EventsService,
    private translation: TranslateService,
  ) {
  }

  async getConnectionType() {
    const status = await this.getStatus();
    return status.connectionType;
  }

  getStatus() {
    return Network.getStatus();
  }

  init() {
    this.watch();

    Network.addListener('networkStatusChange', (status) => {
      this.events.publish('network:changed', status);
    });
  }

  async isConnected(blForceRefresh: boolean = false) {
    const blUseCachedConnectedState = (this.status && !blForceRefresh);
    this.status = blUseCachedConnectedState ? this.status : await this.getStatus();
    return !!this.status.connected;
  }

  showOfflineMessage() {
    this.translation.get('offline_toast_message')
    .subscribe((response: any) => {
      this.events.publish('toast', {
        message: (response || 'offline_toast_message'),
      });
    });
  }

  watch() {
    this.interval = setInterval(async () => {
      try {
        this.isConnected(true);
      } catch(e) {
        console.error('network connection lookup failed', e);
      }
    }, (10 * 1000));
  }

}